import React, { Fragment, useRef, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import {
  Date,
  Gallery,
  ProjectImage,
  Section,
  SectionContent,
  SectionTitle,
  Container,
} from '@components/styledComponents/index';
const Viewer =
  typeof window !== 'undefined'
    ? require('react-viewer').default
    : () => <Fragment />;
import { ViewerContainer } from './styles';
import alkhairHomepage from './projects/2010/Orient-design/Alkhair/1-home-page.jpg';
import alkhairCategory from './projects/2010/Orient-design/Alkhair/2-category.jpg';

import PQICServicespage from './projects/2010/Orient-design/PQIC/2-services.jpg';
import PQICStaffpage from './projects/2010/Orient-design/PQIC/3-staff.jpg';
import PQICContactuspage from './projects/2010/Orient-design/PQIC/4-contactus.jpg';

import PortfolioInterface from './projects/2010/Orient-design/Portfolio/1-interface.jpg';
import PortfolioNewUser from './projects/2010/Orient-design/Portfolio/2-newuser.jpg';
import PortfolioOpenTrade from './projects/2010/Orient-design/Portfolio/3-opentrade.jpg';

import MataamyHomepage from './projects/2010/Orient-design/Mataamy/1-home-page.jpg';
import MataamySearchpage from './projects/2010/Orient-design/Mataamy/2-search-result.jpg';
import Mataamyrestaurantpage from './projects/2010/Orient-design/Mataamy/3-restaurant-page.jpg';
import MataamyChefpage from './projects/2010/Orient-design/Mataamy/5-chef-page.jpg';

export default function OrientDesign() {
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeIndex2, setActiveIndex2] = useState(0);
  const containerViewer = useRef();
  const containerViewer2 = useRef();

  const showPopup = index => {
    setActiveIndex(index);
    setVisible(true);
  };

  const showPopup2 = index => {
    setActiveIndex2(index);
    setVisible2(true);
  };

  return (
    <Container>
      <Section noPadding={true}>
        <SectionTitle>
          <span>Orient Design</span>
          <Date>2010 - 2011</Date>
        </SectionTitle>
        <SectionContent>
          <div>
            Worked in Orient Design as full stack engineer.
            <br />
            Responsibilities : Develop websites, build CMS solutions, create
            banners and widgets using Adobe Flash CS, and Windows application.
          </div>

          <div>
            <h4>Develop websites and desktop apps:</h4>
            Tech: PHP, Wordpress, CodeIgniter, JQuery, Jquery UI, CSS, Flash.
          </div>
          <Gallery>
            <ProjectImage onClick={() => showPopup(0)}>
              <StaticImage
                src="./projects/2010/Orient-design/Portfolio/1-interface.jpg"
                alt="Portfolio interface"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(1)}>
              <StaticImage
                src="./projects/2010/Orient-design/Portfolio/2-newuser.jpg"
                alt="Portfolio new user"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(2)}>
              <StaticImage
                src="./projects/2010/Orient-design/Portfolio/3-opentrade.jpg"
                alt="Portfolio open trade"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>

            <ProjectImage onClick={() => showPopup(3)}>
              <StaticImage
                src="./projects/2010/Orient-design/Alkhair/1-home-page.jpg"
                alt="Al Khair Home page"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>

            <ProjectImage onClick={() => showPopup(4)}>
              <StaticImage
                src="./projects/2010/Orient-design/Alkhair/2-category.jpg"
                alt="Al Khair Category page"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>

            <ProjectImage onClick={() => showPopup(5)}>
              <StaticImage
                src="./projects/2010/Orient-design/PQIC/2-services.jpg"
                alt="PQIC Services page"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>

            <ProjectImage onClick={() => showPopup(6)}>
              <StaticImage
                src="./projects/2010/Orient-design/PQIC/3-staff.jpg"
                alt="PQIC Staff page"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>

            <ProjectImage onClick={() => showPopup(7)}>
              <StaticImage
                src="./projects/2010/Orient-design/PQIC/4-contactus.jpg"
                alt="PQIC Contact us page"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
          </Gallery>

          <div>
            <h4>Mataamy:</h4>
            Marketplace for restaurants, recipes, chefs and retailer.
            <br />
            Tech: PHP, CodeIgniter, Flash, JQuery, Jquery UI, CSS.
          </div>

          <Gallery>
            <ProjectImage onClick={() => showPopup2(0)}>
              <StaticImage
                src="./projects/2010/Orient-design/Mataamy/1-home-page.jpg"
                alt="Mataamy Home page"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>

            <ProjectImage onClick={() => showPopup2(1)}>
              <StaticImage
                src="./projects/2010/Orient-design/Mataamy/2-search-result.jpg"
                alt="Mataamy Search page"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>

            <ProjectImage onClick={() => showPopup2(2)}>
              <StaticImage
                src="./projects/2010/Orient-design/Mataamy/3-restaurant-page.jpg"
                alt="Mataamy Restaurant page"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>

            <ProjectImage onClick={() => showPopup2(3)}>
              <StaticImage
                src="./projects/2010/Orient-design/Mataamy/5-chef-page.jpg"
                alt="Mataamy Chef page"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
          </Gallery>

        </SectionContent>
      </Section>
      <ViewerContainer ref={containerViewer} visible={visible} />
      <ViewerContainer ref={containerViewer2} visible={visible2} />
      <Viewer
        activeIndex={activeIndex}
        container={containerViewer.current}
        visible={visible}
        onClose={() => setVisible(false)}
        onMaskClick={() => setVisible(false)}
        images={[
          { src: PortfolioInterface, alt: 'Portfolio interface ' },
          { src: PortfolioNewUser, alt: 'Portfolio new user ' },
          { src: PortfolioOpenTrade, alt: 'Portfolio open trade ' },
          { src: alkhairHomepage, alt: 'Al Khair home page ' },
          { src: alkhairCategory, alt: 'Al Khair category page ' },
          { src: PQICServicespage, alt: 'PQIC services page ' },
          { src: PQICStaffpage, alt: 'PQIC staff page' },
          { src: PQICContactuspage, alt: 'PQIC contact us page ' },
        ]}
      />

      <Viewer
        activeIndex={activeIndex2}
        container={containerViewer2.current}
        visible={visible2}
        onClose={() => setVisible2(false)}
        onMaskClick={() => setVisible2(false)}
        images={[
          { src: MataamyHomepage, alt: 'Mataamy home page ' },
          { src: MataamySearchpage, alt: 'Mataamy search page ' },
          { src: Mataamyrestaurantpage, alt: 'Mataamy restaurant page' },
          { src: MataamyChefpage, alt: 'Mataamy chef page ' },
        ]}
      />
    </Container>
  );
}
