import React from 'react';
import Layout from '@layouts/index';
import SEO from '@layouts/seo';
import WorksList from '@components/works-list';

export default function Works() {
  return (
    <Layout alterFooter={false} stickHeader={true} hideBio={true}>
      <SEO title="Works" />
      <WorksList />
    </Layout>
  );
}
