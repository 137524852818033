import React, { Fragment, useRef, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import {
  Date,
  Gallery,
  ProjectImage,
  Section,
  SectionContent,
  SectionTitle,
  Container,
} from '@components/styledComponents/index';
import { ViewerContainer } from './styles';
import splash from './projects/2017/Wa9el/1-splash.png';
import login from './projects/2017/Wa9el/2-login.png';
import newOrder from './projects/2017/Wa9el/3-new-order.png';
import orderDetails from './projects/2017/Wa9el/4-new-order-details.png';
import pickDate from './projects/2017/Wa9el/5-pick-date.png';
import pickLocation from './projects/2017/Wa9el/6-pick-location.png';
import confirmOrder from './projects/2017/Wa9el/7-confirm-order.png';
import ordersList from './projects/2017/Wa9el/8-orders-list.png';
import orderMenu from './projects/2017/Wa9el/9-order-menu.png';
import mainMenu from './projects/2017/Wa9el/10-main-menu.png';
import settings from './projects/2017/Wa9el/11-settings.png';
import priceList from './projects/2017/Wa9el/12-price-list.png';
import invoices from './projects/2017/Wa9el/13-invoices.png';
import notifications from './projects/2017/Wa9el/14-notifications.png';
const Viewer =
  typeof window !== 'undefined'
    ? require('react-viewer').default
    : () => <Fragment />;

export default function Wa9el() {
  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const containerViewer = useRef();

  const showPopup = index => {
    setActiveIndex(index);
    setVisible(true);
  };

  return (
    <Container>
      <Section noPadding={true}>
        <SectionTitle>
          <span>Wa9el (aka Wasel)</span>
          <Date>2017 - 2019</Date>
        </SectionTitle>
        <SectionContent>
          <div>
            Wa9el offers a convenient on-demand laundry and dry-cleaning
            services that deliver door to door with one tap, saving the hassle
            of getting laundry done.
            <br />I started my entrepreneur journey with Wa9el, I take all IT
            roles and share a business discussion with my partner. Deployed the
            mobile application on Android and iOS platforms using React native
            and publishing process to mobile stores. In backend I used Node.js,
            Express, Feathers.js and PHP Laravel with RethinkDB. Monitoring
            server performance, generate financial reports, send bulk marketing
            sms and email.
          </div>
          <Gallery>
            <ProjectImage onClick={() => showPopup(0)}>
              <StaticImage
                src="./projects/2017/Wa9el/1-splash.png"
                alt="Wa9el splash"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(1)}>
              <StaticImage
                src="./projects/2017/Wa9el/2-login.png"
                alt="Wa9el splash"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(2)}>
              <StaticImage
                src="./projects/2017/Wa9el/3-new-order.png"
                alt="New order"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(3)}>
              <StaticImage
                src="./projects/2017/Wa9el/4-new-order-details.png"
                alt="New order details"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(4)}>
              <StaticImage
                src="./projects/2017/Wa9el/5-pick-date.png"
                alt="Pick date"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(5)}>
              <StaticImage
                src="./projects/2017/Wa9el/6-pick-location.png"
                alt="Pick location"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(6)}>
              <StaticImage
                src="./projects/2017/Wa9el/7-confirm-order.png"
                alt="Confirm order"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(7)}>
              <StaticImage
                src="./projects/2017/Wa9el/8-orders-list.png"
                alt="Orders list"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(8)}>
              <StaticImage
                src="./projects/2017/Wa9el/9-order-menu.png"
                alt="Order menu"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(9)}>
              <StaticImage
                src="./projects/2017/Wa9el/10-main-menu.png"
                alt="Main menu"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(10)}>
              <StaticImage
                src="./projects/2017/Wa9el/11-settings.png"
                alt="Settings"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(11)}>
              <StaticImage
                src="./projects/2017/Wa9el/12-price-list.png"
                alt="Price list"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(12)}>
              <StaticImage
                src="./projects/2017/Wa9el/13-invoices.png"
                alt="Invoices"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(13)}>
              <StaticImage
                src="./projects/2017/Wa9el/14-notifications.png"
                alt="Notifications"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
          </Gallery>
        </SectionContent>
      </Section>
      <ViewerContainer ref={containerViewer} visible={visible} />
      {Viewer && (
        <Viewer
          activeIndex={activeIndex}
          container={containerViewer.current}
          visible={visible}
          onClose={() => setVisible(false)}
          onMaskClick={() => setVisible(false)}
          images={[
            { src: splash, alt: 'Splash' },
            { src: login, alt: 'Login' },
            { src: newOrder, alt: 'New Order' },
            { src: orderDetails, alt: 'New order details' },
            { src: pickDate, alt: 'Pick date' },
            { src: pickLocation, alt: 'Pick location' },
            { src: confirmOrder, alt: 'Confirm order' },
            { src: ordersList, alt: 'Orders list' },
            { src: orderMenu, alt: 'Order menu' },
            { src: mainMenu, alt: 'Main menu' },
            { src: settings, alt: 'Settings' },
            { src: priceList, alt: 'Price list' },
            { src: invoices, alt: 'Invoices' },
            { src: notifications, alt: 'Notifications' },
          ]}
        />
      )}
    </Container>
  );
}
