import React, { Fragment, useRef, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import {
  Date,
  Gallery,
  ProjectImage,
  Section,
  SectionContent,
  SectionTitle,
  Container,
} from '@components/styledComponents/index';
const Viewer =
  typeof window !== 'undefined'
    ? require('react-viewer').default
    : () => <Fragment />;

import { ViewerContainer } from './styles';

import AlaniControlPanel from './projects/2011/Parmajeh/sites/Alani/control-panel.jpg';
import AlaniHomePage from './projects/2011/Parmajeh/sites/Alani/home-page.jpg';
import AlaniMallPage from './projects/2011/Parmajeh/sites/Alani/mall.jpg';

import intro from './projects/2011/Parmajeh/sites/Heyhee/1-intro.jpg';
import homePage from './projects/2011/Parmajeh/sites/Heyhee/2-home-page.jpg';
import controlPanel from './projects/2011/Parmajeh/sites/Heyhee/3-control-panel.jpg';
import contactUs from './projects/2011/Parmajeh/sites/Heyhee/4-contactus.jpg';
import newArticle from './projects/2011/Parmajeh/sites/Heyhee/5-new-article.jpg';

import phFirstDraft from './projects/2011/Parmajeh/sites/Pharmacists-syndicate/1-first-draft.jpg';
import phSecondtDraft from './projects/2011/Parmajeh/sites/Pharmacists-syndicate/2-second-draft.jpg';
import phHomePage from './projects/2011/Parmajeh/sites/Pharmacists-syndicate/3-home-page.jpg';
import phArticle from './projects/2011/Parmajeh/sites/Pharmacists-syndicate/4-article.jpg';
import phPharmacyDetails from './projects/2011/Parmajeh/sites/Pharmacists-syndicate/5-pharmacy-details.jpg';
import phControlPanel from './projects/2011/Parmajeh/sites/Pharmacists-syndicate/6-control-panel.jpg';
import phListArticles from './projects/2011/Parmajeh/sites/Pharmacists-syndicate/7-list-articles.jpg';
import phContactus from './projects/2011/Parmajeh/sites/Pharmacists-syndicate/8-contactus.jpg';
import phElectionCycle from './projects/2011/Parmajeh/sites/Pharmacists-syndicate/9-election-cycle.jpg';

export default function Parmajeh() {
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [activeIndex1, setActiveIndex1] = useState(0);
  const [activeIndex2, setActiveIndex2] = useState(0);
  const [activeIndex3, setActiveIndex3] = useState(0);
  const containerViewer1 = useRef();
  const containerViewer2 = useRef();
  const containerViewer3 = useRef();

  const showPopup1 = index => {
    setActiveIndex1(index);
    setVisible1(true);
  };

  const showPopup2 = index => {
    setActiveIndex2(index);
    setVisible2(true);
  };

  const showPopup3 = index => {
    setActiveIndex3(index);
    setVisible3(true);
  };

  return (
    <Container>
      <Section noPadding={true}>
        <SectionTitle>
          <span>Parmajeh</span>
          <Date>2011 - 2012</Date>
        </SectionTitle>
        <SectionContent>
          <div>
            I worked with Parmajeh company and get a chance to work in different
            roles banner design, front end and back end developer. Worked mainly
            on client projects and deliver high quality websites.
          </div>

          <div>
            <h4>Alani:</h4>
            A marketplace with multiple malls. stores and wide range of products with VR tour.
            <br />
            Tech: Zend framework, JQuery, Jquery UI, CSS.
          </div>

          <Gallery>
            <ProjectImage onClick={() => showPopup1(0)}>
              <StaticImage
                src="./projects/2011/Parmajeh/sites/Alani/control-panel.jpg"
                alt="Alani control panel"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup1(1)}>
              <StaticImage
                src="./projects/2011/Parmajeh/sites/Alani/home-page.jpg"
                alt="Alani home page"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup1(2)}>
              <StaticImage
                src="./projects/2011/Parmajeh/sites/Alani/mall.jpg"
                alt="Alani mall"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>

          </Gallery>

          <div>
            <h4>Heyhee:</h4>
            A minimal social media with new prespective and simple to use.
            <br />
            Tech: Zend framework, JQuery, Jquery UI, CSS.
          </div>
          <Gallery>
            <ProjectImage onClick={() => showPopup2(0)}>
              <StaticImage
                src="./projects/2011/Parmajeh/sites/Heyhee/1-intro.jpg"
                alt="Heyhee Intro"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup2(1)}>
              <StaticImage
                src="./projects/2011/Parmajeh/sites/Heyhee/2-home-page.jpg"
                alt="Home page"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup2(2)}>
              <StaticImage
                src="./projects/2011/Parmajeh/sites/Heyhee/3-control-panel.jpg"
                alt="Control panel"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup2(3)}>
              <StaticImage
                src="./projects/2011/Parmajeh/sites/Heyhee/4-contactus.jpg"
                alt="Contact us"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup2(4)}>
              <StaticImage
                src="./projects/2011/Parmajeh/sites/Heyhee/5-new-article.jpg"
                alt="New article"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
          </Gallery>

          <div>
            <h4>Pharmacists syndicate in Damascus:</h4>
            The official site of Pharmacists syndicate in Damascus.
            <br />
            Tech: ASP.net, SQL server, WebServices, JQuery, Jquery UI, CSS.
          </div>

          <Gallery>
            <ProjectImage onClick={() => showPopup3(0)}>
              <StaticImage
                src="./projects/2011/Parmajeh/sites/Pharmacists-syndicate/1-first-draft.jpg"
                alt="First draft"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup3(1)}>
              <StaticImage
                src="./projects/2011/Parmajeh/sites/Pharmacists-syndicate/2-second-draft.jpg"
                alt="Second draft"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup3(2)}>
              <StaticImage
                src="./projects/2011/Parmajeh/sites/Pharmacists-syndicate/3-home-page.jpg"
                alt="Home page"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup3(3)}>
              <StaticImage
                src="./projects/2011/Parmajeh/sites/Pharmacists-syndicate/4-article.jpg"
                alt="Article"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup3(4)}>
              <StaticImage
                src="./projects/2011/Parmajeh/sites/Pharmacists-syndicate/5-pharmacy-details.jpg"
                alt="Pharmacy details"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup3(5)}>
              <StaticImage
                src="./projects/2011/Parmajeh/sites/Pharmacists-syndicate/6-control-panel.jpg"
                alt="Control panel"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup3(6)}>
              <StaticImage
                src="./projects/2011/Parmajeh/sites/Pharmacists-syndicate/7-list-articles.jpg"
                alt="List articles"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup3(7)}>
              <StaticImage
                src="./projects/2011/Parmajeh/sites/Pharmacists-syndicate/8-contactus.jpg"
                alt="Contact us"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup3(8)}>
              <StaticImage
                src="./projects/2011/Parmajeh/sites/Pharmacists-syndicate/9-election-cycle.jpg"
                alt="Election cycle"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
          </Gallery>
        </SectionContent>
      </Section>
      <ViewerContainer ref={containerViewer1} visible={visible1} />
      <ViewerContainer ref={containerViewer2} visible={visible2} />
      <ViewerContainer ref={containerViewer3} visible={visible3} />

      <Viewer
        activeIndex={activeIndex1}
        container={containerViewer1.current}
        visible={visible1}
        onClose={() => setVisible1(false)}
        onMaskClick={() => setVisible1(false)}
        images={[
          { src: AlaniControlPanel, alt: 'Alani control panel' },
          { src: AlaniHomePage, alt: 'Alani home page' },
          { src: AlaniMallPage, alt: 'Alani mall page' },
        ]}
      />

      <Viewer
        activeIndex={activeIndex2}
        container={containerViewer2.current}
        visible={visible2}
        onClose={() => setVisible2(false)}
        onMaskClick={() => setVisible2(false)}
        images={[
          { src: intro, alt: 'Hayhee Intro page ' },
          { src: homePage, alt: 'Hayhee Home page ' },
          { src: controlPanel, alt: 'Hayhee Control panel ' },
          { src: contactUs, alt: 'Hayhee Contact us ' },
          { src: newArticle, alt: 'Hayhee New article ' },
        ]}
      />

      <Viewer
        activeIndex={activeIndex3}
        container={containerViewer3.current}
        visible={visible3}
        onClose={() => setVisible3(false)}
        onMaskClick={() => setVisible3(false)}
        images={[
          { src: phFirstDraft, alt: 'First draft ' },
          { src: phSecondtDraft, alt: 'Second draft ' },
          { src: phHomePage, alt: 'Home page ' },
          { src: phArticle, alt: 'Article ' },
          { src: phPharmacyDetails, alt: 'Pharmacy details ' },
          { src: phControlPanel, alt: 'Control panel ' },
          { src: phListArticles, alt: 'List articles ' },
          { src: phContactus, alt: 'Contact us ' },
          { src: phElectionCycle, alt: 'Election cycle ' },
        ]}
      />
    </Container>
  );
}
