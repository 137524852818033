import React from 'react';
import { Container } from '@components/styledComponents/index';
import Wa9el from './wa9el';
import Jeetak from './Jeetak';
import Maysalward from './Maysalward';
import Parmajeh from './Parmajeh';
import OrientDesign from './OrientDesign';
import WhaleSoft from './Whalesoft';

export default function WorksList() {
  return (
    <Container>
      <Wa9el />
      <Jeetak />
      <Maysalward />
      <Parmajeh />
      <OrientDesign />
      <WhaleSoft />
    </Container>
  );
}
