import styled from 'styled-components';

export const ViewerContainer = styled.div`
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99;
`;
