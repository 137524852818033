import React, { Fragment, useRef, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import {
  Date,
  Gallery,
  ProjectImage,
  Section,
  SectionContent,
  SectionTitle,
  Container,
} from '@components/styledComponents/index';
const Viewer =
  typeof window !== 'undefined'
    ? require('react-viewer').default
    : () => <Fragment />;
import { ViewerContainer } from './styles';

import ZapMashroomStartGameOne from './projects/2012/Maysalward/ZapMashroom/1-start-screen.jpg';
import ZapMashroomStartGameSecond from './projects/2012/Maysalward/ZapMashroom/2-start-screen.jpg';
import ZapMashroomGameStage from './projects/2012/Maysalward/ZapMashroom/3-game-stage.jpg';
import ZapMashroomFinalScore from './projects/2012/Maysalward/ZapMashroom/4-final-score.jpg';
import ZapMashroomHelp from './projects/2012/Maysalward/ZapMashroom/5-help.jpg';
import ZapMashroomGameIcon from './projects/2012/Maysalward/ZapMashroom/6-game-icon.jpg';

import TravelSheikhMapOne from './projects/2012/Maysalward/TravelSheikh/1-map.jpg';
import TravelSheikMapTwo from './projects/2012/Maysalward/TravelSheikh/2-map.jpg';
import TravelSheikhGameQuestions from './projects/2012/Maysalward/TravelSheikh/3-game-questions.jpg';
import TravelSheikhGameMatching from './projects/2012/Maysalward/TravelSheikh/4-matching-game.jpg';
import TravelSheikhGameCollaps from './projects/2012/Maysalward/TravelSheikh/5-collapse-game.jpg';
import TravelSheikhFindWord from './projects/2012/Maysalward/TravelSheikh/6-find-word-game.jpg';

export default function Maysalward() {
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeIndex2, setActiveIndex2] = useState(0);
  const containerViewer = useRef();
  const containerViewer2 = useRef();

  const showPopup = index => {
    setActiveIndex(index);
    setVisible(true);
  };

  const showPopup2 = index => {
    setActiveIndex2(index);
    setVisible2(true);
  };

  return (
    <Container>
      <Section noPadding={true}>
        <SectionTitle>
          <span>Maysalward</span>
          <Date>2012 - 2013</Date>
        </SectionTitle>
        <SectionContent>
          <div>
            Worked in Maysalward as game developer.
            <br />
            Responsibilities : Develop Facebook games, mobile games using Adobe
            Flash CS, Adobe Air and Starling Framework.
          </div>

          <div>
            <h4>ZapMashroom game (Facebook game, iOS and Android game):</h4>
            Tech: Adobe Flash, Adobe Flash builder, Adobe Air, Starling
            framework.
          </div>
          <Gallery>
            <ProjectImage onClick={() => showPopup(0)}>
              <StaticImage
                src="./projects/2012/Maysalward/ZapMashroom/1-start-screen.jpg"
                alt="Zapmashroom start screen one"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>

            <ProjectImage onClick={() => showPopup(1)}>
              <StaticImage
                src="./projects/2012/Maysalward/ZapMashroom/2-start-screen.jpg"
                alt="Zapmashroom start screen two"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>

            <ProjectImage onClick={() => showPopup(2)}>
              <StaticImage
                src="./projects/2012/Maysalward/ZapMashroom/3-game-stage.jpg"
                alt="Zapmashroom stage game"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>

            <ProjectImage onClick={() => showPopup(3)}>
              <StaticImage
                src="./projects/2012/Maysalward/ZapMashroom/4-final-score.jpg"
                alt="Zapmashroom final score"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>

            <ProjectImage onClick={() => showPopup(4)}>
              <StaticImage
                src="./projects/2012/Maysalward/ZapMashroom/5-help.jpg"
                alt="Zapmashroom help screen"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>

            <ProjectImage onClick={() => showPopup(5)}>
              <StaticImage
                src="./projects/2012/Maysalward/ZapMashroom/6-game-icon.jpg"
                alt="Zapmashroom Game icon"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
          </Gallery>

          <div>
            <h4>TravelSheikh game:</h4>
            A game to test countries and tourism information.
            <br />
            Tech: Adobe Flash, Adobe Flash builder, Adobe Air, Starling
            framework.
          </div>

          <Gallery>
            <ProjectImage onClick={() => showPopup2(0)}>
              <StaticImage
                src="./projects/2012/Maysalward/TravelSheikh/1-map.jpg"
                alt="Travel Sheikh map one"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>

            <ProjectImage onClick={() => showPopup2(1)}>
              <StaticImage
                src="./projects/2012/Maysalward/TravelSheikh/2-map.jpg"
                alt="Travel Sheikh map two"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>

            <ProjectImage onClick={() => showPopup2(2)}>
              <StaticImage
                src="./projects/2012/Maysalward/TravelSheikh/3-game-questions.jpg"
                alt="Travel Sheikh questions game"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>

            <ProjectImage onClick={() => showPopup2(3)}>
              <StaticImage
                src="./projects/2012/Maysalward/TravelSheikh/4-matching-game.jpg"
                alt="Travel Sheikh matching game"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>

            <ProjectImage onClick={() => showPopup2(4)}>
              <StaticImage
                src="./projects/2012/Maysalward/TravelSheikh/5-collapse-game.jpg"
                alt="Travel Sheikh collapse game"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>

            <ProjectImage onClick={() => showPopup2(5)}>
              <StaticImage
                src="./projects/2012/Maysalward/TravelSheikh/6-find-word-game.jpg"
                alt="Travel Sheikh find word game"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
          </Gallery>
        </SectionContent>
      </Section>
      <ViewerContainer ref={containerViewer} visible={visible} />
      <ViewerContainer ref={containerViewer2} visible={visible2} />
      <Viewer
        activeIndex={activeIndex}
        container={containerViewer.current}
        visible={visible}
        onClose={() => setVisible(false)}
        onMaskClick={() => setVisible(false)}
        images={[
          {
            src: ZapMashroomStartGameOne,
            alt: 'Zapmashroom start screen one ',
          },
          {
            src: ZapMashroomStartGameSecond,
            alt: 'Zapmashroom start screen two ',
          },
          { src: ZapMashroomGameStage, alt: 'Zapmashroom start game stage ' },
          { src: ZapMashroomFinalScore, alt: 'Zapmashroom final score ' },
          { src: ZapMashroomHelp, alt: 'Zapmashroom help ' },
          { src: ZapMashroomGameIcon, alt: 'Zapmashroom game icon ' },
        ]}
      />

      <Viewer
        activeIndex={activeIndex2}
        container={containerViewer2.current}
        visible={visible2}
        onClose={() => setVisible2(false)}
        onMaskClick={() => setVisible2(false)}
        images={[
          { src: TravelSheikhMapOne, alt: 'Travel Sheikh map one ' },
          { src: TravelSheikMapTwo, alt: 'Travel Sheikh map two ' },
          { src: TravelSheikhGameQuestions, alt: 'Travel Sheikh questions game ' },
          { src: TravelSheikhGameMatching, alt: 'Travel Sheikh matching game ' },
          { src: TravelSheikhGameCollaps, alt: 'Travel Sheikh collaps game ' },
          { src: TravelSheikhFindWord, alt: 'Travel Sheikh find word game ' },
        ]}
      />
    </Container>
  );
}
