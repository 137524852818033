import React, { Fragment, useRef, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import {
  Date,
  Gallery,
  ProjectImage,
  Section,
  SectionContent,
  SectionTitle,
  Container,
} from '@components/styledComponents/index';
const Viewer =
  typeof window !== 'undefined'
    ? require('react-viewer').default
    : () => <Fragment />;
import { ViewerContainer } from './styles';
import login from './projects/2018/Jeetak/1-login.png';
import listStore from './projects/2018/Jeetak/2-list-stores.png';
import newOrder from './projects/2018/Jeetak/3-new-order.png';
import pickLocation from './projects/2018/Jeetak/4-pick-location.png';
import notifications from './projects/2018/Jeetak/5-notifications.png';
import confirmRequest from './projects/2018/Jeetak/6-confirm-request.png';
import systemNotification from './projects/2018/Jeetak/7-system-notification.png';
import orderChat from './projects/2018/Jeetak/8-order-chat.png';
import ratingDelivery from './projects/2018/Jeetak/9-rating-delivery.png';
import packages from './projects/2018/Jeetak/10-packages.png';
import profile from './projects/2018/Jeetak/11-profile.png';
import settings from './projects/2018/Jeetak/12-settings.png';
import newOffer from './projects/2018/Jeetak/13-new-offer.png';
import sendInvoice from './projects/2018/Jeetak/14-send-invoice.png';
import rating from './projects/2018/Jeetak/15-rating.png';

export default function Jeetak() {
  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const containerViewer = useRef();

  const showPopup = index => {
    setActiveIndex(index);
    setVisible(true);
  };

  return (
    <Container>
      <Section noPadding={true}>
        <SectionTitle>
          <span>Jeetak</span>
          <Date>2018 - 2019</Date>
        </SectionTitle>
        <SectionContent>
          <div>
            Jeetak offers a convenient on-demand food and packages deliver
            services that deliver items to door with one tap, saving the hassle
            of consume time and efforts to collect items.
            <br />
            The client provide the design and essential requirements. I assist him to build
            business and operating plan.
            <br />
            The mobile application deployed to Android and iOS platforms using
            React native, published to mobile stores. In the backend I
            used Node.js, Express, Feathers.js with RethinkDB. Dashboard built using React, Bootstrap and scss.
          </div>
          <Gallery>
            <ProjectImage onClick={() => showPopup(0)}>
              <StaticImage
                src="./projects/2018/Jeetak/1-login.png"
                alt="Jeetak login"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(1)}>
              <StaticImage
                src="./projects/2018/Jeetak/2-list-stores.png"
                alt="List stores"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(2)}>
              <StaticImage
                src="./projects/2018/Jeetak/3-new-order.png"
                alt="New order"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(3)}>
              <StaticImage
                src="./projects/2018/Jeetak/4-pick-location.png"
                alt="Pick location"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(4)}>
              <StaticImage
                src="./projects/2018/Jeetak/5-notifications.png"
                alt="Notifications"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(5)}>
              <StaticImage
                src="./projects/2018/Jeetak/6-confirm-request.png"
                alt="Confirm request"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(6)}>
              <StaticImage
                src="./projects/2018/Jeetak/7-system-notification.png"
                alt="System notification"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(7)}>
              <StaticImage
                src="./projects/2018/Jeetak/8-order-chat.png"
                alt="Order chat"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(8)}>
              <StaticImage
                src="./projects/2018/Jeetak/9-rating-delivery.png"
                alt="Rating delivery"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(9)}>
              <StaticImage
                src="./projects/2018/Jeetak/10-packages.png"
                alt="Packages delivery service"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(10)}>
              <StaticImage
                src="./projects/2018/Jeetak/11-profile.png"
                alt="Profile"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(11)}>
              <StaticImage
                src="./projects/2018/Jeetak/12-settings.png"
                alt="Settings"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(12)}>
              <StaticImage
                src="./projects/2018/Jeetak/13-new-offer.png"
                alt="New offer"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(13)}>
              <StaticImage
                src="./projects/2018/Jeetak/14-send-invoice.png"
                alt="Send Invoice"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(14)}>
              <StaticImage
                src="./projects/2018/Jeetak/15-rating.png"
                alt="Rating"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
          </Gallery>
        </SectionContent>
      </Section>
      <ViewerContainer ref={containerViewer} visible={visible} />
      <Viewer
        activeIndex={activeIndex}
        container={containerViewer.current}
        visible={visible}
        onClose={() => setVisible(false)}
        onMaskClick={() => setVisible(false)}
        images={[
          { src: login, alt: 'Login' },
          { src: listStore, alt: 'List store' },
          { src: newOrder, alt: 'New order' },
          { src: pickLocation, alt: 'Pick location' },
          { src: notifications, alt: 'Notifications' },
          { src: confirmRequest, alt: 'Confirm request' },
          { src: systemNotification, alt: 'System notification' },
          { src: orderChat, alt: 'Order chat' },
          { src: ratingDelivery, alt: 'Rating delivery' },
          { src: packages, alt: 'Packages delivery service' },
          { src: profile, alt: 'Profile' },
          { src: settings, alt: 'Settings' },
          { src: newOffer, alt: 'New offer' },
          { src: sendInvoice, alt: 'Send invoice' },
          { src: rating, alt: 'Rating' },
        ]}
      />
    </Container>
  );
}
