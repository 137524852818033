import React, { Fragment, useRef, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import {
  Date,
  Gallery,
  ProjectImage,
  Section,
  SectionContent,
  SectionTitle,
  Container,
} from '@components/styledComponents/index';
const Viewer =
  typeof window !== 'undefined'
    ? require('react-viewer').default
    : () => <Fragment />;

import { ViewerContainer } from './styles';
import splash from './projects/2010/Whalesoft/Edu/1-splash.jpg';
import dashboard from './projects/2010/Whalesoft/Edu/2-dashboard.jpg';
import student from './projects/2010/Whalesoft/Edu/3-student.jpg';
import createProgram from './projects/2010/Whalesoft/Edu/4-create-program.jpg';
import attend from './projects/2010/Whalesoft/Edu/5-attend.jpg';
import backup from './projects/2010/Whalesoft/Edu/6-backup.jpg';
import finance from './projects/2010/Whalesoft/Edu/7-finance.jpg';
import reports from './projects/2010/Whalesoft/Edu/8-reports.jpg';
import reports2 from './projects/2010/Whalesoft/Edu/9-reports2.jpg';

export default function WhaleSoft() {
  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const containerViewer = useRef();

  const showPopup = index => {
    setActiveIndex(index);
    setVisible(true);
  };

  return (
    <Container>
      <Section noPadding={true}>
        <SectionTitle>
          <span>Whalesoft - Edu</span>
          <Date>2009 - 2010</Date>
        </SectionTitle>
        <SectionContent>
          <div>
            Whalesoft was my first startup, after graduation my friend and I
            setup our consulting services, started by build websites for our
            clients, publish our own product EDU.
            <br />
            <br />
            EDU is an ERP system for education institutions, powring many
            features such as students, teachers, employees profiles.
            <br />
            Create courses schedules, attendence, exams, advance and custom
            reports.
          </div>
          <Gallery>
            <ProjectImage onClick={() => showPopup(0)}>
              <StaticImage
                src="./projects/2010/Whalesoft/Edu/1-splash.jpg"
                alt="Edu Splash"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(1)}>
              <StaticImage
                src="./projects/2010/Whalesoft/Edu/2-dashboard.jpg"
                alt="Edu Dashboard"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(2)}>
              <StaticImage
                src="./projects/2010/Whalesoft/Edu/3-student.jpg"
                alt="Edu Student profile"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(3)}>
              <StaticImage
                src="./projects/2010/Whalesoft/Edu/4-create-program.jpg"
                alt="Edu create program"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(4)}>
              <StaticImage
                src="./projects/2010/Whalesoft/Edu/5-attend.jpg"
                alt="Edu Attendance"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(5)}>
              <StaticImage
                src="./projects/2010/Whalesoft/Edu/6-backup.jpg"
                alt="Edu backup"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(6)}>
              <StaticImage
                src="./projects/2010/Whalesoft/Edu/7-finance.jpg"
                alt="Edu Finance"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(7)}>
              <StaticImage
                src="./projects/2010/Whalesoft/Edu/8-reports.jpg"
                alt="Edu Reports 1"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
            <ProjectImage onClick={() => showPopup(8)}>
              <StaticImage
                src="./projects/2010/Whalesoft/Edu/9-reports2.jpg"
                alt="Edu Reports 2"
                placeholder="blurred"
                objectFit="contain"
                width={100}
                height={100}
              />
            </ProjectImage>
          </Gallery>
        </SectionContent>
      </Section>
      <ViewerContainer ref={containerViewer} visible={visible} />
      <Viewer
        activeIndex={activeIndex}
        container={containerViewer.current}
        visible={visible}
        onClose={() => setVisible(false)}
        onMaskClick={() => setVisible(false)}
        images={[
          { src: splash, alt: 'Splash ' },
          { src: dashboard, alt: 'Dashboard ' },
          { src: student, alt: 'Student profile ' },
          { src: createProgram, alt: 'Create classes program ' },
          { src: attend, alt: 'Attendence ' },
          { src: backup, alt: 'Backup database ' },
          { src: finance, alt: 'Finance management ' },
          { src: reports, alt: 'Activity reports ' },
          { src: reports2, alt: 'General reports ' },
        ]}
      />
    </Container>
  );
}
